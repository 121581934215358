import React, { useState, useEffect } from "react";
//Components
import PanelContainer from "../components/Layouts/PanelContainer";
import PageTitle from "../components/Admin/PageTitle";
import Table from "../components/Table";
import Loading from "../components/Loading";
//API
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const APIS = { get: "/get_all_users", suspend: "suspend_user" };

const PanelScreen = () => {
  const axiosPrivate = useAxiosPrivate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  //Filters
  const [filteredData, setFilteredData] = useState([]);
  const [idFilter, setIdFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [ageFilter0, setAgeFilter0] = useState("");
  const [ageFilter1, setAgeFilter1] = useState("");
  const [signFilter0, setSignFilter0] = useState("");
  const [signFilter1, setSignFilter1] = useState("");
  const [genderFilter, setGenderFilter] = useState("");
  const [suspendTime, setSuspendTime] = useState(0);

  const [id, setId] = useState();

  const values = [
    {
      title: "ID",
      value: "_id",
      filter: {
        title: "ID",
        state: idFilter,
        setState: setIdFilter,
        type: "input",
      },
    },
    {
      title: "Ad Soyad",
      value: "name",
    },
    {
      title: "Telefon",
      value: "phone",
      is_phone: true,
    },
    {
      title: "Cinsiyet",
      value: "gender_id",
      is_gender: true,
      filter: {
        title: "Cinsiyet",
        state: genderFilter,
        setState: setGenderFilter,
        type: "choiceinput",
        options: [
          { _id: "", name: "Hepsi" },
          { _id: "0", name: "Bilinmiyor" },
          { _id: "1", name: "Kadın" },
          { _id: "2", name: "Erkek" },
        ],
        option_title: "name",
      },
    },
    {
      title: "Şehir",
      value: "city_id",
      is_city: true,
    },
    {
      title: "Doğum Tarihi",
      value: "birth_date",
      is_birth: true,
      filter: {
        title: "Min Yaş",
        title1: "Max Yaş",
        state: ageFilter0,
        setState: setAgeFilter0,
        state1: ageFilter1,
        setState1: setAgeFilter1,
        type: "ages",
      },
    },
    {
      title: "Kayıt Tarihi",
      value: "created_at",
      is_birth: true,
      filter: {
        title: "Min Tarih",
        title1: "Max Tarih",
        state: signFilter0,
        setState: setSignFilter0,
        state1: signFilter1,
        setState1: setSignFilter1,
        type: "ages",
      },
    },
    {
      title: "Askıya Al",
      is_suspend: true,
    },
  ];

  useEffect(() => {
    const fetchDatas = async () => {
      setLoading(true);
      try {
        let parameters = {};
        const response = await axiosPrivate.post(
          APIS.get,
          JSON.stringify(parameters),
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        setLoading(false);
        if (response.status === 200) {
          setData(response.data.data);
        }
      } catch (err) {
        //alert(err);
        setLoading(false);
        // TODO: Errorhandling..
      }
    };

    fetchDatas();
  }, []);

  //FilterEffect
  useEffect(() => {
    if (
      idFilter === "" &&
      emailFilter === "" &&
      ageFilter0 === "" &&
      ageFilter1 === "" &&
      genderFilter === "" &&
      signFilter0 === "" &&
      signFilter1 === ""
    ) {
      setFilteredData(data);
    } else {
      let new_data = data;
      if (idFilter !== "") {
        new_data = new_data.filter(
          (i) => i._id.toLowerCase().indexOf(idFilter.toLowerCase()) > -1
        );
      }
      if (emailFilter !== "") {
        new_data = new_data.filter(
          (i) => i.email.toLowerCase().indexOf(emailFilter.toLowerCase()) > -1
        );
      }
      if (ageFilter0 !== "") {
        new_data = new_data.filter(
          (i) =>
            parseInt(i.birth_date.split("-")[0]) <
            parseInt(new Date().getFullYear()) - parseInt(ageFilter0)
        );
      }
      if (ageFilter1 !== "") {
        new_data = new_data.filter(
          (i) =>
            parseInt(i.birth_date.split("-")[0]) >
            parseInt(new Date().getFullYear()) - parseInt(ageFilter1)
        );
      }
      if (genderFilter !== "") {
        new_data = new_data.filter((i) => i.gender_id === genderFilter);
      }
      if (signFilter0 !== "") {
        new_data = new_data.filter(
          (i) =>
            parseInt(i.created_at.split("-")[0]) <
            parseInt(new Date().getFullYear()) - parseInt(signFilter0)
        );
      }
      if (signFilter1 !== "") {
        new_data = new_data.filter(
          (i) =>
            parseInt(i.created_at.split("-")[0]) >
            parseInt(new Date().getFullYear()) - parseInt(signFilter1)
        );
      }
      setFilteredData(new_data);
    }
  }, [
    data,
    idFilter,
    emailFilter,
    ageFilter0,
    ageFilter1,
    genderFilter,
    signFilter0,
    signFilter1,
  ]);

  const suspendHandler = async () => {
    try {
      let parameters = {
        user_id: id,
        month: suspendTime,
      };
      const response = await axiosPrivate.post(
        APIS.suspend,
        JSON.stringify(parameters),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status === 200) {
        alert("kullanıcı başarıyla askıya alındı.");
      }
    } catch (err) {
      console.log(err);
      //alert(err);
      setLoading(false);
      // TODO: Errorhandling..
    }
  };

  return (
    <PanelContainer>
      {loading ? (
        <Loading />
      ) : (
        <>
          <PageTitle title={"Üyeler"} total={filteredData.length} />
          <Table
            values={values}
            data={filteredData}
            loading={false}
            onSuspend={suspendHandler}
            setSuspend={setSuspendTime}
            setId={setId}
          />
        </>
      )}
    </PanelContainer>
  );
};

export default PanelScreen;

import React, { useEffect, useState } from "react";
//Components
import PanelContainer from "../components/Layouts/PanelContainer";
import PageTitle from "../components/Admin/PageTitle";
import Table from "../components/Table";
import AddEdit from "../components/AddEdit";
import Loading from "../components/Loading";
//API
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const APIS = {
  get: "/get_all_notifications",
  add: "/send_notifications",
  //update: "/update_notification",
  //delete: "/delete_notification",
};

const NotificationsScreen = () => {
  const axiosPrivate = useAxiosPrivate();

  const [data, setData] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  //Values
  const [titleTR, setTitleTR] = useState("");
  const [titleEN, setTitleEN] = useState("");
  const [messageTR, setMessageTR] = useState("");
  const [messageEN, setMessageEN] = useState("");
  //Filters
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetchDatas();
  }, [editOpen]);

  const fetchDatas = async () => {
    setLoading(true);
    try {
      let parameters = {};
      const response = await axiosPrivate.post(
        APIS.get,
        JSON.stringify(parameters),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status === 200) {
        setData(response.data.data);
        setFilteredData(response.data.data);
      }
    } catch (err) {
      setLoading(false);
      // TODO: Errorhandling..
    }
  };
  const handleAdd = async () => {
    setLoading(true);
    try {
      let parameters = {
        title: { tr: titleTR, en: titleEN },
        message: {
          tr: messageTR,
          en: messageEN,
        },
      };
      const response = await axiosPrivate.post(
        APIS.add,
        JSON.stringify(parameters),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        setEditOpen(false);
        fetchDatas();
      }
    } catch (err) {
      setLoading(false);
      alert("Bir sorun oluştu, lütfen tekrar deneyiniz.");
    }
  };

  const resetValues = (item) => {
    setTitleTR(item === undefined ? "" : item.title.tr);
    setTitleEN(item === undefined ? "" : item.title.en);
    setMessageTR(item === undefined ? "" : item.message.tr);
    setMessageEN(item === undefined ? "" : item.message.en);
  };

  const values = [
    {
      title: "Başlık (Türkçe)",
      value: "title.tr",
      type: "textinput",
      state: titleTR,
      setState: setTitleTR,
    },
    {
      title: "Başlık (İngilizce)",
      value: "title.en",
      not_visible: true,
      type: "textinput",
      state: titleEN,
      setState: setTitleEN,
    },
    {
      title: "Mesaj (Türkçe)",
      value: "message.tr",
      type: "textinput",
      state: messageTR,
      setState: setMessageTR,
    },
    {
      title: "Mesaj (İngilizce)",
      value: "message.en",
      not_visible: true,
      type: "textinput",
      state: messageEN,
      setState: setMessageEN,
    },
  ];

  return (
    <PanelContainer>
      {loading ? (
        <Loading />
      ) : editOpen ? (
        <AddEdit
          goBack={() => {
            setEditOpen(false);
          }}
          values={values}
          edit={edit}
          onEditPress={handleAdd}
        />
      ) : (
        <>
          <PageTitle
            title={"Bildirimler"}
            notification={true}
            total={filteredData.length}
            onPress={() => {
              resetValues();
              setEdit(true);
              setEditOpen(true);
            }}
          />
          <Table values={values} data={filteredData} loading={false} />
        </>
      )}
    </PanelContainer>
  );
};

export default NotificationsScreen;

const PrivacyPolicy = `Gizlilik Sözleşmesi

KısmetÇarkı uygulamasını Google Play ve Apple Store’dan ücretsiz olarak indirilir. Uygulama içinde yapacağınız satın almalar uygulama içi ödeme (mobil ödeme) olarak gerçekleştirilir.


Sitemize üye olurken üyelerimiz herhangi bir kimlik bilgisi vermemektedir. Bu bilgilerin dağıtılması, yayınlanması, açıklanması sitemiz tarafından söz konusu olamaz.

Sitemizde kayıt sırasında verilen eposta adresleri ve telefon numaraları 3. şahıslardan gizli tutulur ve asla diğer üyelerle paylaşılmaz.

Standart Üyeliğini Gold, Platinum üyeliğe yükselten, ek paket, mesaj paketi ve özel tema satın alan kullanıcılarımızın tüm ödemeleri uygulama içi satın alma olarak yapılır. Bu ödemeler Google ve Apple Inc. tarafından tahsil edilir. Aynı zamanda sitemiz bu bilgileri kullanarak üyelerinden otomatik abonelik tahsilatı yapabilir. Aboneliklerinin otomatik uzatılmasını istemeyen üyelerin ayarlar bölümünden otomatik ödeme tahsilatını kapatmaları gerekmektedir.

 
Kullanıcı Sözleşmesi
Aşağıdaki KısmetÇarkı  ile KısmetÇarkı  üyesi arasındaki üyelik sözleşmesinin metnidir. KısmetÇarkı’na üye olmadan önce lütfen bu sözleşmeyi dikkatle okuyunuz ve sözleşmenin içerdiği tüm maddeleri onaylamadan siteye üyeliğinizi tamamlamayınız. Yeni üyelik esnasında, bu metni okuduğunuzu söyleyen bir kutucukla karşılaşacaksınız; bu kutucuğu işaretlemeniz, aşağıdaki maddelerin tamamını kabul ettiğiniz anlamına gelecektir.
 
Genel Kullanıcı Kurallarımız & Etiklerimiz
1. KısmetÇarkı, yalnızca 18 yaşından büyükler içindir. KısmetÇarkı üye formunu dolduran herkes 18 yaşından büyük olduğunu taahhüt eder. Bu koşula uygun olmadığı anlaşılan üyelikler geçersiz kılınır. Bu kullanım şartına uymayan üyelerin sebep olacakları zarar ya da her türlü olumsuzluktan üyelerin kendileri sorumlu olacak olup, KısmetÇarkı kesin suretle sorumlu tutulmayacaktır.
2. KısmetÇarkı üyeliği kişiseldir. Başkasına ödünç verilemez, devredilemez. Böyle girişimlere bağlı sorunlardan KısmetÇarkı’nın hiçbir hukuki veya cezai sorumluluğu bulunmayacaktır.  KısmetÇarkı üyelerinin beyanlarının doğruluğunu esas alır. Yanlış bilgi ve aldatıcı içerik yazan üyelerin bu durumlarının tespiti halinde üyelikleri sonlandırılır. Buna sebebiyet veren üyeler hakkında yasal yollara başvurulabileceği gibi, KısmetÇarkı aleyhine olası bir hukuki uyuşmazlığa sebebiyet verilmesi halinde KısmetÇarkı buna sebebiyet veren üyeye rücu edebilecektir.
3. KısmetÇarkı’nda her kullanıcı adı bir kere kullanılır. Üye olan herkes, kullanıcı adı ve şifresini korumakla yükümlüdür. Oluşabilecek kusurlu davranışlardan ve seçilen kullanıcı adlarından KısmetÇarkı sorumlu değildir.
4. KısmetÇarkı’na üye olurken seçilen şifre sadece üye tarafından bilinir. Şifrenin seçimi ve korunma sorumluluğu üyeye aittir. KısmetÇarkı şifre kullanımından doğacak sorunlardan sorumlu değildir.
5. KısmetÇarkı’nda oluşturulan profiller, taliplere izin verilmediği müddetçe gizlidir. Talip’leri kabul eden üyeler, profillerini kendi rızaları ile paylaşmış olurlar. KısmetÇarkı’nda paylaşılan tüm özel bilgi ve içerikler üyelerin kendi sorumluluğundadır. Bu konudan doğacak hiçbir sorundan ya da anlaşmazlıktan veya gizlilik ihlalininden KısmetÇarkı sorumlu tutulmayacaktır. 
6. KısmetÇarkı’nda üyelerimizin kendileri veya aradıkları kişi ile ilgili şahsi görüş ve düşüncelerini yazabildikleri “Kullanıcı Adı” ve “ Profil Mesajı, Hakkımda, Hobilerim, Favorilerim, Fotoğraf Albümü veya Sayfa İçeriği” alanlarına, hiçbir şekilde kişisel iletişim bilgilerini (mail, adı soyadı, telefon, açık adres vb.) yazmaları yasaktır. Ayrıca Sohbet Odalarında yayınlanan mesajlar, konu başlıkları ve tüm içerikler ile ilgili KısmetÇarkı sorumlu tutulamaz. Bu alanlarda kullanıcılar açıkça yada dolaylı yollardan suç teşkil edecek, yasal açıdan takip gerektirecek; yerel ve ulusal yasalara ya da uluslararası anlaşmalara ters düşecek bir durum yaratan ya da böyle durumları teşvik eden, hiçbir tür yasadışı, tehditkar, rahatsız edici, hakaret, küfür içeren, küçük düşürücü, kaba, pornografik, rahatsız edici, terör amaçlı, holigan içerik, hassas içerikli, cinsel içerikli, sapkın ya da ahlaka aykırı bilgi postalayamaz ya da iletemez.
7. KısmetÇarkı’nda paylaşım yapılan bilgiler ya da görseller sistem tarafından otomatik olarak sitenin çeşitli yerlerinde yayınlanabilir yada çark’tan eşleşen taliplerle paylaşılabilir. KısmetÇarkı bu bilgi ve veya görsellerin yayınlanması veya kullanılması gibi sebeplerle sorumlu tutulamaz.
8. KısmetÇarkı’nda mesaj merkezindeki mesaj sayısı, ilgili sayfada beyan edilen limitleri aşan üyelerin mesajları en eski tarihliden başlayarak silinebilir. Sisteme bir ay boyunca hiç giriş yapmayan üyelerin tüm mesajları silinebilir.
9. Favori listesindeki kayıtlar, mesajlar ve imojeler geçmişten başlayan kayıtlarla silinebilir.
10. Tüm KısmetÇarkı kullanıcıları ve üyeleri, KısmetÇarkı uygulamasını birbirine saygılı, anlayışlı, dürüst, kibar, sabırlı, sağduyulu, olumlu ve sevgi çerçevesinde kullanacaktır.  
11. İnteraktif alanlar:
 a) Bir kullanıcı, başka bir kullanıcının interaktif alanlarını istediği gibi kullanmasını engelleyemez.
 b) Kullanıcı suç teşkil edecek, yasal açıdan takip gerektirecek; yerel ve ulusal yasalara ya da uluslararası anlaşmalara ters düşecek bir durum yaratan ya da böyle durumları teşvik eden, hiçbir tür yasadışı, tehditkar, rahatsız edici, hakaret, küfür içeren, küçük düşürücü, kaba, pornografik, rahatsız edici, terör amaçlı, holigan içerik, hassas içerik ya da ahlaka aykırı bilgi postalayamaz ya da iletemez. Böyle bir durumun varlığının KısmetÇarkı tarafından fark edilmesi halinde KısmetÇarkı ilgili üyenin üyeliğini dilediği koşulda sonlandırabileceği gibi ilgili kişi hakkında yasal yollara başvurabilir.
 c) Kullanıcı bir başkasının gizlilik hakkını ya da yayın haklarını çiğneyen, telif hakları, ticari marka hakları veya başka mülkiyet hakları tarafından korunan ya da bu tarif edilen sınıflara giren malzemelerden uyarlanan hiçbir bilgi, yazılım ya da başka bir malzemeyi sahibinden ya da haklarını elinde tutan kişiden izin almaksızın, postalayamaz ya da iletemez.
 d) Bu web sitesi, sadece bireylerin kişisel kullanımına açıktır ve hiçbir şekilde ticari amaçlarla kullanılamaz. Herhangi bir iş çevresi, şirket veya organizasyon herhangi bir amaçla bu siteye üye olamaz.
 e) KısmetÇarkı, doğrudan sistemde bulunan kullanıcılara ya da onların e-posta adreslerine, ticari ya da reklam amaçlı istek dışı mesaj gönderilmesi, üyelerin bu amaçla başka bir web sitesine yönlendirilmesi ve sitedeki interaktif alanların bu şekilde illegal ve yetki dışında kullanılması gibi durumlarda hukuki yollara başvurma hakkını saklı tutar.
 f) Üyeler, ticari amaç taşıyan ya da reklam içeren hiçbir bilgi, yazılım ya da malzeme postalayamaz ya da iletemez. Diğer üyelerden herhangi bir ürün satın almak ya da onlara ürün satmak gibi bir talepte ve reklam faaliyetinde bulunamaz. Bu amaçla üyelere yönelik e-posta zincirleri kurulamaz; junk e-posta gönderilemez.
 g) Üyeleri bu tip reklam faaliyetleri ya da ısrardan korumak için tedbir amacıyla KısmetÇarkı, bir üyenin diğer üyelere göndereceği mesajları kısıtlama hakkını saklı tutmaktadır.
12. KısmetÇarkı site dahilindeki interaktif alanlarda üyelerin yazdıklarından sorumlu değildir; buralarda yazılan görüşleri onaylamak ve yayınlamak zorunda değildir. Uygulama yöneticileri lüzum gördüğü hallerde yayınlanan içerik, post, görsel ve fikir vs tümünü yayından, yayınlayana haber vermek zorunda olmaksızın kaldırma yetkisine sahiptir.
13. KısmetÇarkı link verdiği, banner tanıtımı yaptığı, sponsor yada reklam aldığı marka ve sitelerin içeriklerinden sorumlu değildir.
14. Site üyesi, sitenin genel kullanım alanında yayınlanan her türlü bilginin dünya çapında kullanım, sergileme ve dağıtım hakkını, geri alınamaz, sürekli, özel olmayan, tamamen ücreti ödenmiş olarak KısmetÇarkı’na vermiş olur. KısmetÇarkı, üyelerinin verdiği ve veri tabanında bulunan bilgileri, istatistiksel bilgilere dönüştürmek, reklam ve pazarlama alanında kullanmak, site kullanıcılarının genel eğilimlerini belirlemek, içeriğini ve servislerini zenginleştirmek için kullanabilme hakkına sahiptir.
15. KısmetÇarkı, üyelerinden gelen evlilik ve mutluluk hikayelerini ve bu hikayelere ilişkin fotoğrafları, İnternet ortamında ve yazılı tanıtım malzemelerinde kullanabilir.
16. KısmetÇarkı’nda bulunan bütün yazılı, resimli, sesli, grafik içeren ve/veya içermeyen tablo gibi materyallerin her hakkı saklıdır. Bu materyalleri kopyalayarak kişisel ve ticari sayfalarda yayınlamak ve/veya pazarlamak kesinlikle yasaktır. Kişisel sayfalarda bu gibi materyallerin kullanılması halinde mutlaka kimden alıntı yapıldığı ve/veya alıntı yapılan şahıs ve ticari kurumun izin verdiğini belirten bir ibarenin sayfanın altına eklenmesi zorunludur. Aksi halde doğabilecek kanuni ihtilaflarda KısmetÇarkı hiçbir sorumluluk kabul etmez.
17. Teknik aksaklıklardan dolayı KısmetÇarkı ‘nda hata ya da kesinti olabilir. KısmetÇarkı, verdiği hizmetin teknik sebeplerle kesilmesinden sorumlu değildir. KısmetÇarkı, telefon ve network ağı, çevrimiçi sistemler, servis sağlayıcıları, bilgisayar teçhizatı ve yazılımları, İnternet trafiğindeki tıkanıklık, üyelere veya herhangi bir kişiye zarar verecek yazılımlar yüklemek gibi teknik konulardan doğan aksaklıklardan sorumlu değildir.
18. KısmetÇarkı, üyeler ve üyeler arasındaki iletişimle ilgili herhangi bir hata, ihmal, kesinti, silinme, bozulma, işlem veya iletimde gecikme ya da iletişim ağı başarısızlığından sorumlu değildir.
19. KısmetÇarkı tüm üyelerinden kayıt anında mail adreslerini ve telefon numaralarını ister. Bu mail adresinin geçerli bir mail adresi ve telefon numarasının geçerli bir telefon numarası olması gerekmektedir. Bu bilgiler üçüncü şahıslarla hiçbir şekilde paylaşılmaz. Üyelerimizle iletişim mail adresleri ve telefon numaraları aracılığıyla yapılmaktadır. Geçersiz mail adresi ve telefon numarası veren kullanıcılar uyarılabilir, ya da üyelikleri silinebilir. 

ÜYELİK TİPLERİ
Standart Üyelik
Genel kullanım koşullarını kabul ederek, profil oluşturan tüm kişiler standart üye olurlar. Standart üyeler, profil bilgilerini genel ilkelere uygun oluşturmak, kullanım koşullarına uygun hareket etmekle yükümlüdürler. Kullanıcı Adı seçiminde tüm üyelerimiz dikkatli olması gerekmektedir. Kullanıcı Adı altında iletişim bilgilerini veren, ahlak ve kanunlara aykırı Kullancı Adı kullanan üyelerin üyelikleri, uyarı yapmaksızın silinecektir. Profil başlığı ve mesajları bölümünde hiçbir şekilde kişisel iletişim bilgileri paylaşılamaz. Bu kısımlarda siyasi propaganda, ticari reklam, cinsellik, hakaret, ayrımcılık yapamaz, küçük düşürücü, ırkçı ifadeler ve hassas içerik kullanılamaz. Profil başlığı ve mesajı bölümleri, üyenin kendisini ya da aradığı kişiyi genel olarak anlattığı kısımlardır. Amacı dışında kullanımdan sadece üye sorumludur. KısmetÇarkı hiçbir şekilde sorumlu tutulamaz. Üyelerin gerçek dışı beyanlarda bulunduğu ya da profil bilgilerinde tutarsızlık olduğu KısmetÇarkı yönetimi tarafından tespit edilirse, üyelikleri sonlandırılacaktır.
Standart üyeler, sitemizde günlük 5 kez talip olma haklarını limitleri içerisinde kullanabilirler. Her yeni gün yeniden 5 kez talip olma hakkı kazanırlar. Sohbet odalarında site etik ve kuralları dahilinde sınırsız sohbet edip, sosyalleşebilirler.
Standart Üyelik İptali
Üyelerimiz dilerlerse üyeliklerini iptal edebilirler. Sayfadaki üyelik iptali kısmından, kayıt esnasında girmiş oldukları mail adreslerini girerek üyelikleri iptal olunabilir. İptal olan üyenin tüm bilgileri sistemden silinecektir. Üyeliğini iptal eden üyemiz, daha sonra dilerse tekrar üye olabilir kullanıcı adı bir başka üye tarafından kullanılmıyor ise aynı ad ile yeniden üye olabilirler. 
Silver Üyelik

Silver üyelik, sitemizde standart üyeden bir derece daha etkili olan üyelik tipidir ve ücretsizdir. Silver Üyeler 1 ay içerisinde en az 100 kısmete talip olmalıdır. Silver üyeliğini korumak isteyen kullanıcılar bunu her ay tekrar etmelidir.

Gold Üyelik
Gold üyeler Silver üyelikten daha etkili bir üyelik tipidir. Aylık 300 TL + KDV ödeyerek Gold Üyelik satın alınabilmektedir. Gold Üyeler standart haklarının yanısıra günde ekstradan 10 kez daha talip olma hakkına sahiptir. Ayrıca Gold Üyeler isterlerse çarklarında Standart ve Silver üyelerin dışında sadece kendileri gibi Gold Üye çıkarma hakkına da sahiptir. 
Platinum Üyelik diğer tüm üyeliklerden daha etkili bir üyelik tipidir. Aylık 500 TL + KDV ödeyerek Platinum Üyelik satın alınabilmektedir. Platinum Üyeler standart haklarının yanısıra günde ekstradan 20 kez daha talip olma hakkına sahiptir. Ayrıca Platinum Üyeler isterlerse çarklarında diğer üyelerin dışında sadece Platinum Üye çıkarma hakkına da sahiptir.

Yukarıda bahsedilen Gold ve Platinum Üyelik tiplerimiz site yönetimince belirlenen süre ve fiyat kriterlerine göre ödeme yapmak ile aktif edilen bir işlemlerdir. Ödeme işlemleri sırasında yaşanabilecek sorunlardan KısmetÇarkı sorumlu tutulamaz.
Yukarıda belirtilen Üyelikler, site kullanım koşulları ve genel ilkeler açısından kişiye ayrıcalık sağlamaz. Tüm hükümler istisnasız uygulanır ve denetlenir. Genel kurallara uymadığı tespit edilen, şikâyet alan, uyarılmalarına rağmen uygunsuz davranışlarda ısrar eden özel üyelerin üyelikleri iptal edilebilir. İptal durumunda, herhangi bir geri ödeme ya da kullanılmamış dönemlere ait ücretlerin iadesi söz konusu değildir. Üyeliklerini kendi iptal eden özel üyelerimize de herhangi bir geri ödeme yapılması söz konusu değildir.
Her yapılan dönem Gold, Platinum Üyelik, Ek Paket ve İmoje Grupları satışları için mobil ödeme sistemi kullanılmakta olup, faturalandırma işlemi ilgili operatör tarafından gerçekleştirilecektir. KısmetÇarkı sadece aracı kurumlara fatura kesmekle yükümlüdür.

 GİZLİLİK İLKELERİ ve GÜVENLİK
KısmetÇarkı sistemini güvenlik açısından, yazılımsal ve donanımsal olarak güvence altına almıştır. Sistem 24 saat denetim altındadır.
Kredi kartı ödemelerinde doğacak problemler üye ile banka arasındadır. Bu tip sorunlardan KısmetÇarkı sorumlu tutulamaz.
Üyelerimiz site içindeki mesajlarında hiçbir şekilde kişisel bilgilerini, banka bilgilerini, kredi kartı numaralarını yazmamalıdırlar. Mesaj gönderme ekranlarımızdaki uyarıları dikkatle okumalıdırlar. Bu tip bilgiler üçüncü şahıslar tarafından görüntülenebilir, KısmetÇarkı bu tip bilgi aktarımının güvenliğini garanti edemez ve bu konuda hiçbir sorumluluk almaz.
Üyelerimiz sitedeki oturumlarını “ güvenli çıkış” yaparak kapatmalıdırlar. Aksi takdirde başka şahısların bilgilerine ulaşmalarından ötürü KısmetÇarkı sorumu tutulamaz.
KısmetÇarkı, çevrimiçi ya da çevrimdışı konumdaki hiçbir üyenin davranışlarından sorumlu değildir. Hiçbir koşulda sitenin kullanımı, içeriği, çevrimiçi ya da çevrimdışı konumda olsunlar üyeler arasındaki iletişim ve etkileşimden doğan sonuçlardan, -kayıp, hasar, zarar, ölüm, vs. dahi olsa- sorumlu değildir.
KısmetÇarkı, üyeler arasındaki anlaşmazlıkları takip etme hakkına sahiptir ama buna zorunlu değildir. Üyeler arasındaki iletişim, etkileşim ve ilişkiler, tamamen üyelerin kendi sorumluluğu altındadır. Bir üyenin yayınladığı içerik unsuru, zararlı, hatalı ya da aldatıcı olabilir. Bu durumda diğer üyelerin dikkatli ve sağduyulu davranmaları gerekmektedir. Üyeler arasındaki anlaşmazlıklardan doğan herhangi bir maddi ya da manevi zarardan KısmetÇarkı sorumlu değildir.
KısmetÇarkı üyelerinin şikâyette bulunduğu üyelerin üyeliklerini değerlendirme sonucu iptal edilebilir. KısmetÇarkı şikâyet eden üyeleri gizli tutmak nedeni ile ispatta bulunmak zorunda değildir. KısmetÇarkı kapatılan ve iptal edilen üyelik ile ilgili sorumlu tutulamaz.
KısmetÇarkı aracılığı ile tanışan üyelerin birbirlerini tanımakla yükümlüdür. Bu kişilerle yaşayacakları flörtler, ilişkiler ve evliliklerde yaşanacak sorunlardan KısmetÇarkı sorumlu tutulamaz.
KısmetÇarkı yöneticileri üyelikleri her ne kadar denetlese de, üyeler arasında yaşanabilecek dolandırıcılık olaylarında yaşanan ikili sorunlardan KısmetÇarkı sorunlu tutulamaz.
Üye, KısmetÇarkı’nın yürürlükteki şartları gereğince resmi makamlara açıklama yapmakla yükümlü olduğu durumlar içersinde, resmi makamlarca usulü dairesinde bu bilgilerin talep edilmesi halinde üyelere ait gizli/özel/ticari bilgileri resmi makamlara açıklamaya yetkili olacağını ve bu sebeple KısmetÇarkı’dan her ne nam altında olursa olsun tazminat talep edilemeyeceğini kabul eder.
Üye, KısmetÇarkı içerisinde diğer üyelerle olan sorunlarında, Üyelerin Nasipse.com içerisinde yaşadığı herhangi bir sorunla KısmetÇarkı’ndan her ne nam altında olursa olsun tazminat talep edilemeyeceğini kabul eder.
Üye, Gold veya Platinum üye olsa dahi, KısmetÇarkı’na gelen şikâyetler nedeni ile şikâyet edilen üyenin üyeliğini iptal edebilir. Üyelik iptalleri yapılan üyelerin KısmetÇarkı ‘dan her ne nam altında olursa olsun tazminat talep edilemeyeceğini kabul eder.
Üye, bu sözleşmenin ihlali durumunda meydana gelebilecek olan kayıp, iddia ve suçlamalardan doğabilecek olan zararların karşılığında KısmetÇarkı’nın, onun şubelerinin, yetkililerinin, temsilcilerinin ve diğer çalışanlarının zararlarını karşılayacağını kabul eder.
Bu sözleşmenin yorumlanmasında ve bu sözleşme dâhilinde doğan hukuki ilişkilerin yönetiminde Türk Hukuku uygulanacaktır. Sözleşmeden dolayı doğan ve doğabilecek her türlü ihtilafın hallinde İstanbul Mahkemeleri ve İcra Daireleri yetkilidir.
Üyelik formunda yer alan "üyelik sözleşmesini okudum, kabul ediyorum" kutusunun işaretlenmesi yukarıdaki koşulların kabul edilmiş olması anlamına gelmektedir.
Bu sayfada yer alan maddeler, değişikliklerin bu sayfadan belirtilmesi şartıyla KısmetÇarkı tarafından istendiği zaman değiştirilebilir.

 

`;

export default PrivacyPolicy;

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD3pUbDlP2ZNLvbJJ7bm3zupaUltVmZdwk",
  authDomain: "kismet-carki.firebaseapp.com",
  projectId: "kismet-carki",
  storageBucket: "kismet-carki.appspot.com",
  messagingSenderId: "763120072052",
  appId: "1:763120072052:web:e5edbd82b48e550937a821",
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
